import React, {
  useEffect, useState, ReactNode, useRef,
} from 'react';
import PropTypes from 'prop-types';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import {
  Button, Container, Form, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import './notesPanel.css';
import { Link } from 'react-router-dom';
import { AsyncState } from '../../utils/webRequests.type';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import {
  getRelatedProductNotes, postProductNote,
} from '../../controllers/product-service';
import {
  RelatedNotesResponse,
} from '../../controllers/product-service/types';
import { selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import SnackBar from '../snackBar/SnackBar';
import Note from './note';
import SearchInput from '../SearchInput/SearchInput';
import { getTextColor } from '../../utils/stringFormatting';
import sendRumError from '../../utils/datadogRum';

type NotesPanelProps = {
  productId: string | null,
  setShowNotesCallback: (value: boolean) => void,
  afterClose: () => void | undefined,
  show: boolean,
};

type NotesUpdateErrorType = 'DELETE' | 'MISSING_PRODUCT_ID' | 'POST';
const propTypes = {
  productId: PropTypes.string.isRequired,
  setShowNotesCallback: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  afterClose: PropTypes.func.isRequired,
};

const updateErrorTitle: { [key in NotesUpdateErrorType]: string } = {
  DELETE: 'Notes Error',
  MISSING_PRODUCT_ID: 'Untracked Product',
  POST: 'Notes Error',
};

const updateErrorText: { [key in NotesUpdateErrorType]: string | JSX.Element } = {
  DELETE: 'There was an error deleting a note',
  MISSING_PRODUCT_ID: (
    <span>
      Notes cannot be added for untracked products. To track this product, navigate to
      {' '}
      <Link
        className="ma-link"
        to="/itemAdmin"
        target="_blank"
        rel="noopener noreferrer"
      >
        Item Administration
      </Link>
      .
    </span>
  ),
  POST: 'There was an error posting a note',
};

const MAX_NOTE_LENGTH = 512;

export default function NotesPanel(
  {
    productId,
    setShowNotesCallback,
    show,
    afterClose,
  }: NotesPanelProps,
) {
  const overlaySKUContainerRef = useRef(null);
  const [loadState, setLoadState] = useState<AsyncState>('uninitialized');
  const [postState, setPostState] = useState<AsyncState>('uninitialized');
  const [updateErrorType, setUpdateErrorType] = useState<NotesUpdateErrorType | null>(null);
  const [displayAllRetailers, setDisplayAllRetailers] = useState<boolean>(false);
  const [productNotesResponse, setProductNotesResponse] = useState<RelatedNotesResponse>({
    notes: [],
    products: [],
  });
  const [noteSearchText, setNoteSearchText] = useState<string>('');
  const [noteText, setNoteText] = useState<string>('');
  const currentOrgID = useSelector(selectCurrentOrgId);

  const loadNotesData = () => getRelatedProductNotes(productId as string, currentOrgID)
    .then((response) => {
      if (response.data.products.length === 0) {
        setLoadState('failed');
        return;
      } if (response.data.products.length >= 1) {
        const { products } = response.data;
        const product = products.find((p) => p.id === productId);
        if (product === undefined || product.status !== 'active') {
          setUpdateErrorType('MISSING_PRODUCT_ID');
          setLoadState('failed');
          return;
        }
      }
      setProductNotesResponse(response.data);
      setShowNotesCallback(true);
      setLoadState('completed');
    })
    .catch((error) => {
      sendRumError(error);
      setLoadState('failed');
    });

  const isValidProductId = (s: string | null) => {
    if (s === null) {
      return false;
    }
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(s);
  };

  useEffect(() => {
    if (!show) {
      return;
    }
    if (!isValidProductId(productId)) {
      setUpdateErrorType('MISSING_PRODUCT_ID');
      setShowNotesCallback(true);
    } else if (productId && productId.length > 0) {
      setUpdateErrorType(null);
      setLoadState('loading');
      loadNotesData();
    } else {
      setShowNotesCallback(false);
    }
  }, [show, productId]);

  const onSubmit = () => {
    setPostState('loading');
    postProductNote(currentOrgID, productId as string, noteText)
      .then(() => {
        setUpdateErrorType(null);
        setPostState('completed');
        setNoteText('');
      })
      .catch((error) => {
        sendRumError(error);
        setUpdateErrorType('POST');
        setPostState('failed');
      })
      .finally(() => {
        setLoadState('loading');
        loadNotesData();
      });
  };

  const { notes: notesSource, products } = productNotesResponse;

  notesSource.sort(
    (noteA, noteB) => Date.parse(noteB.created_at) - Date.parse(noteA.created_at),
  );

  const sourceProduct = products.find(
    (product) => product.id !== undefined && product.id === productId,
  );

  const productToRetailer: { [productId: string]: string } = products.reduce((acc, cur) => ({
    ...acc,
    [cur.id ?? '']: cur.retailer_name,
  }), {});

  let res: ReactNode;

  const notesToDisplay = notesSource
    .filter(
      (note) => (
        noteSearchText === ''
        || note.note.toLocaleLowerCase().includes(noteSearchText)
        || note.created_by_name.toLocaleLowerCase().includes(noteSearchText)
        || note.created_by_email.toLocaleLowerCase().includes(noteSearchText)
      )
        && (displayAllRetailers || note.product_id === sourceProduct?.id),
    );

  switch (loadState) {
    case 'failed':
      res = (
        <Container className="col-12 center h-50 text-muted border-dark justify-content-center d-flex">
          <div className="top-50 position-relative">
            <span>There was an error loading notes for this product.</span>
          </div>
        </Container>
      );
      break;
    case 'completed':
      res = (
        <div className="col-12 center h-100 border-dark">
          <div className="notes-product-item-container">
            {
              notesToDisplay.length === 0
                ? (
                  <p className="text-muted center text-center top-75 position-relative pt-5 pb-5">
                    No notes were found for this product.
                  </p>
                )
                : null
            }
            {

              notesToDisplay.map((note) => (
                <Note
                  key={`product-node-item-${note.id}`}
                  note={note}
                  retailerName={productToRetailer[note.product_id]}
                  afterDelete={() => {
                    setLoadState('loading');
                    loadNotesData();
                  }}
                />
              ))
            }
          </div>
          <div className="notes-panel-new-item-container col-12 bg-quicksight-grey text-center px-2 py-3">
            <textarea
              className="form-control shadow-coral"
              maxLength={MAX_NOTE_LENGTH}
              onChange={(event) => setNoteText(event.currentTarget.value)}
              rows={5}
              value={noteText}
              placeholder="Enter your note here"
              disabled={loadState !== 'completed' || updateErrorType === 'MISSING_PRODUCT_ID'}
            />
            <div className={`note-entry-length-container text-end ${getTextColor(noteText.length, MAX_NOTE_LENGTH)}`}>
              {`${noteText.length}/${MAX_NOTE_LENGTH}`}
            </div>
            <Button
              data-testid="create-new-note-button"
              className="col-6 ma-button mt-2 create-new-note-button mx-auto"
              onClick={onSubmit}
              disabled={noteText.length === 0}
            >
              {
                postState === 'loading'
                  ? (
                    <div className="fit-spinner">
                      <Spinner size="sm" animation="grow" />
                    </div>
                  )
                  : 'Create New Note'
              }
            </Button>
          </div>
        </div>
      );
      break;
    case 'loading':
      res = <LoadingPage />;
      break;
    default:
      res = (<p>something went wrong</p>);
      break;
  }

  return (
    <SlidingPane
      isOpen={show && productId !== ''}
      from="right"
      shouldCloseOnEsc
      overlayClassName="notes-panel"
      width="500px"
      className="border"
      hideHeader
      onRequestClose={() => {
        setNoteText('');
        afterClose();
      }}
    >
      <div className="bg-quicksight-grey notes-header p-2">
        <div className="d-flex">
          <p className="fs-6 col-5 d-inline-flex mb-0 ms-1">
            Item Notes
          </p>
          <div className="d-flex col-7">
            <strong className="bold small me-1 note-header-attribute-label down-1-8">
              Mfr. SKU:
            </strong>
            <div className="note-header-attribute-value down-1-8" ref={overlaySKUContainerRef}>
              <OverlayTrigger
                placement="right"
                flip
                container={overlaySKUContainerRef.current}
                delay={{ show: 0, hide: 400 }}
                overlay={(
                  <Tooltip>
                    {sourceProduct?.manufacturer_sku ?? '...'}
                  </Tooltip>
                )}
              >
                <div
                  className="small text-truncate mb-0"
                  style={{ maxWidth: '11rem' }}
                >
                  {sourceProduct?.manufacturer_sku ?? '...'}
                </div>
              </OverlayTrigger>
            </div>
          </div>
          <button
            type="button"
            className="btn-close d-inline-flex ms-auto me-2 position-absolute end-0"
            aria-label="close"
            data-testid="close-notes-panel-button"
            onClick={() => {
              setShowNotesCallback(false);
            }}
          />
        </div>
        <div className="d-flex notes-second-row mt-1">
          <div className="col-5 d-inline-flex">
            <div className="ms-1 me-2 small">See All Retailers</div>
            <Form.Check
              type="switch"
              className="align-self-end"
              onChange={(event) => setDisplayAllRetailers(event.currentTarget.checked)}
              checked={displayAllRetailers}
            />
          </div>
          <div className="d-flex col-7 ms-1">
            <strong className="bold small me-1 note-header-attribute-label">
              Retailer:
            </strong>
            <div className="note-header-attribute-value">
              <p className="small text-truncate mb-0">
                {displayAllRetailers ? 'All Retailers' : sourceProduct?.retailer_name ?? '...'}
              </p>
            </div>
          </div>
        </div>
        <div className="container p-0">
          <div className="col-12">
            <SearchInput
              className="border border-rounded my-1 mx-4 form-control-sm p-0"
              placeholder="Search notes"
              onChange={(e) => setNoteSearchText(e.currentTarget.value.toLocaleLowerCase())}
            />
          </div>
        </div>
      </div>
      {
        updateErrorType === null ? null : (
          <SnackBar
            header={updateErrorTitle[updateErrorType]}
            show={updateErrorType !== null}
            alertText={updateErrorText[updateErrorType]}
            style={{
              position: 'absolute',
              width: '500px',
              zIndex: 1000,
              top: '7.5rem',
            }}
            onClose={() => setUpdateErrorType(null)}
          />
        )
      }
      {res}
    </SlidingPane>
  );
}

NotesPanel.prototype = propTypes;

import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './NavFooter.css';
import { publicPageList } from '../../utils/constants';

const NavFooter: React.FC = function NavBar() {
  const location = useLocation();

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [hash]);

  const navBarLogoPublicImg = (
    <img
      id="navbar-logo-public"
      src="/assets/images/insights-large-logo-black.svg"
      alt="marshall-logo"
      style={{
        height: '2rem',
        width: 'auto',
      }}
    />
  );

  // the public NavFooter
  if (publicPageList.includes(location.pathname)) {
    return (
      <Container className="footer-container mx-auto">
        <footer className="row row-cols-1 row-cols-md-4 py-2 mt-5 mb-2 border-bottom">
          <div className="col mb-3 px-3">
            <Link
              to="/home"
              className="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              {navBarLogoPublicImg}
            </Link>
          </div>

          <div className="col mb-3 px-3">
            <h5>Why Insights?</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><Link to="/home#public-home-ecomm-central" className="nav-link p-0 text-muted">How We Help</Link></li>
              <li className="nav-item mb-2"><Link to="/home" className="nav-link p-0 text-muted">About Our Company</Link></li>
              <li className="nav-item mb-2"><Link to="/home" className="nav-link p-0 text-muted">Case Studies</Link></li>
            </ul>
          </div>

          <div className="col mb-3 px-3">
            <h5>Features</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><Link to="/features#pricing-feature-group" className="nav-link p-0 text-muted">Product Monitoring</Link></li>
              <li className="nav-item mb-2"><Link to="/features#sales-feature-group" className="nav-link p-0 text-muted">Sales &amp; Advertising Analytics</Link></li>
              <li className="nav-item mb-2"><Link to="/features#share-of-voice-feature-group" className="nav-link p-0 text-muted">Share of Voice</Link></li>
            </ul>
          </div>

          <div className="col mb-3 px-3">
            <h5>Resources</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><Link to="#home" className="nav-link p-0 text-muted">Data Dictionary</Link></li>
              <li className="nav-item mb-2"><Link to="/training" className="nav-link p-0 text-muted">Training</Link></li>
              <li className="nav-item mb-2"><Link to="/help" className="nav-link p-0 text-muted">Help</Link></li>
            </ul>
          </div>
        </footer>
        <footer className="row row-cols-1 row-cols-md-4">
          <div className="col mb-3 px-3">
            &copy; 2023. All Rights Reserved.
          </div>
          <div className="col mb-3 px-3">
            <Link to="privacy-policy" className="nav-link p-0 text-muted">
              Privacy Policy
            </Link>
          </div>
          <div className="col mb-3 px-3">
            <Link to="/terms-of-service" className="nav-link p-0 text-muted">
              Terms of Service
            </Link>
          </div>
          <div className="col mb-3 px-3">
            <Link to="/cookie-policy" className="nav-link p-0 text-muted">
              Cookie Policy
            </Link>
          </div>
        </footer>
      </Container>
    );
  }

  return null;
};

export default NavFooter;

import DCAInstructionsWalmartConnectAdvertising from '../../components/DCAInstructions/DCAInstructionsWalmartConnectAdvertising';
import { DataCollectionAccountWithSettings } from '../../controllers/subscription-service/types';
import createDataCollectionConfig from './types';

enum OfferedSubscriptionIdEnum {
  walmart_connect_advertising_us = '20ebcc5a-5322-48ba-b873-688174d86677',
}

// const marketplaces = ['US'] as const;

const VisibleOfferedSubscriptions: ReadonlyArray<OfferedSubscriptionIdEnum> = [
  OfferedSubscriptionIdEnum.walmart_connect_advertising_us,
];

const SubscriptionQueryTranslations: Readonly<
Partial<
Record<
OfferedSubscriptionIdEnum, Record<string, string
>
>
>
> = {};
const SubscriptionSettingUniqueValues: Readonly<Record<OfferedSubscriptionIdEnum, string[]>> = {
  [OfferedSubscriptionIdEnum.walmart_connect_advertising_us]: [
    'walmart_connect_advertiser_id',
    'walmart_connect_advertiser_name',
  ],
};

const DCAInstructionsComponent: Record<OfferedSubscriptionIdEnum, React.FC | JSX.Element> = {
  [
  OfferedSubscriptionIdEnum.walmart_connect_advertising_us
  ]: DCAInstructionsWalmartConnectAdvertising,
};

// const authUrls: Readonly<Record<Marketplace, string>> = {
//   US: '',
// }

const disableAccountNameEntry: ReadonlyArray<OfferedSubscriptionIdEnum> = [
  OfferedSubscriptionIdEnum.walmart_connect_advertising_us,
];

// const getAuthUrl = (
//   marketplaceCodes: Marketplace[],
// ): string | null => (
//   (marketplaceCodes.length > 0 && marketplaceCodes[0] in authUrls)
//     ? (authUrls[marketplaceCodes[0]] || null)
//     : null
// );

export const authorize = (_dca: DataCollectionAccountWithSettings): void | Error => new Error('This Subscription is not implemented');

export const hasRequiredSettings = (_dca: DataCollectionAccountWithSettings): boolean => false;

export default createDataCollectionConfig<OfferedSubscriptionIdEnum>(
  OfferedSubscriptionIdEnum,
  VisibleOfferedSubscriptions,
  SubscriptionQueryTranslations,
  SubscriptionSettingUniqueValues,
  disableAccountNameEntry,
  authorize,
  hasRequiredSettings,
  DCAInstructionsComponent,
);

/* istanbul ignore file */

import React from 'react';
import createDataCollectionConfig from './types';
import { authorizeAmazonAdvertisingApp, getCountryCodeFromDCA } from './sharedMethods';
import { DataCollectionAccountWithSettings } from '../../controllers/subscription-service/types';
import DCAInstructionsAmazonAdvertising from '../../components/DCAInstructions/DCAInstructionsAmazonAdvertising';

enum OfferedSubscriptionIdEnum {
  search_advertising_gas_ae = '196ef39a-930c-44a1-98e7-1c5565bf74c6',
  search_advertising_gas_br = '0538a268-fc9f-4572-9c45-56d8c01e356d',
  search_advertising_gas_ca = '442457e8-f3f2-43e3-89ad-c83cb8375cdb',
  search_advertising_gas_de = 'db71e3e6-373f-4d58-8ca2-024356e00e4a',
  search_advertising_gas_es = 'fc47a04d-fc42-482d-bd7f-0206080f1dcc',
  search_advertising_gas_fr = '3c272812-90e6-4ead-a0ac-2f7f1ab7f56d',
  search_advertising_gas_it = '718c0cfd-096f-4573-8ccd-6a7ac328a50d',
  search_advertising_gas_mx = '2d359464-080c-4c26-bd0a-1cb7438d1045',
  search_advertising_gas_uk = '3a3f6a8b-7cd0-4922-82e1-55a31b07f4d4',
  search_advertising_gas_us = '65328726-dbbd-4116-abe5-72c973e3b6e3',
}

const countryCodes = ['AE', 'BR', 'CA', 'DE', 'ES', 'FR', 'IT', 'MX', 'UK', 'US'] as const;
type CountryCode = typeof countryCodes[number];

const VisibleOfferedSubscriptions: ReadonlyArray<OfferedSubscriptionIdEnum> = [
  OfferedSubscriptionIdEnum.search_advertising_gas_ae,
  OfferedSubscriptionIdEnum.search_advertising_gas_br,
  OfferedSubscriptionIdEnum.search_advertising_gas_ca,
  OfferedSubscriptionIdEnum.search_advertising_gas_de,
  OfferedSubscriptionIdEnum.search_advertising_gas_es,
  OfferedSubscriptionIdEnum.search_advertising_gas_fr,
  OfferedSubscriptionIdEnum.search_advertising_gas_it,
  OfferedSubscriptionIdEnum.search_advertising_gas_mx,
  OfferedSubscriptionIdEnum.search_advertising_gas_uk,
  OfferedSubscriptionIdEnum.search_advertising_gas_us,
];

const baseSubscriptionQueryTranslation: Readonly<Record<string, string>> = {
  code: '__oauth_grant_code',
};

const SubscriptionQueryTranslations: Readonly<
Partial<Record<OfferedSubscriptionIdEnum, Readonly<Record<string, string>>>>
> = {
  [OfferedSubscriptionIdEnum.search_advertising_gas_ae]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.search_advertising_gas_br]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.search_advertising_gas_ca]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.search_advertising_gas_de]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.search_advertising_gas_es]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.search_advertising_gas_fr]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.search_advertising_gas_it]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.search_advertising_gas_mx]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.search_advertising_gas_uk]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.search_advertising_gas_us]: baseSubscriptionQueryTranslation,
};

// can't use the Readonly<> typing here.. conflicts with the parent object.
const baseUniqueValues: string[] = [
  'profile_id',
];

// can't use the Readonly<> typing here.. conflicts with the parent object.
const SubscriptionSettingUniqueValues: Readonly<
Partial<Record<OfferedSubscriptionIdEnum, string[]>>
> = {
  [OfferedSubscriptionIdEnum.search_advertising_gas_ae]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.search_advertising_gas_br]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.search_advertising_gas_ca]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.search_advertising_gas_de]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.search_advertising_gas_es]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.search_advertising_gas_fr]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.search_advertising_gas_it]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.search_advertising_gas_mx]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.search_advertising_gas_uk]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.search_advertising_gas_us]: baseUniqueValues,
};

const consentRequestUrls: Readonly<Record<string, string>> = {
  NORTH_AMERICA: 'https://www.amazon.com/ap/oa',
  EUROPE: 'https://eu.account.amazon.com/ap/oa',
  FAR_EAST: 'https://apac.account.amazon.com/ap/oa',
};

const authUrls: Readonly<Record<CountryCode, string>> = {
  AE: consentRequestUrls.EUROPE,
  BR: consentRequestUrls.NORTH_AMERICA,
  CA: consentRequestUrls.NORTH_AMERICA,
  DE: consentRequestUrls.EUROPE,
  ES: consentRequestUrls.EUROPE,
  FR: consentRequestUrls.EUROPE,
  IT: consentRequestUrls.EUROPE,
  MX: consentRequestUrls.NORTH_AMERICA,
  UK: consentRequestUrls.EUROPE,
  US: consentRequestUrls.NORTH_AMERICA,
};

const getAuthUrl = (
  countryCode: CountryCode,
): URL | null => (
  (countryCode in authUrls)
    ? new URL(authUrls[countryCode])
    : null
);

// ! Missing instructions...
const dcaInstructionsComponent: Record<OfferedSubscriptionIdEnum, React.FC | JSX.Element> = {
  [OfferedSubscriptionIdEnum.search_advertising_gas_ae]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.search_advertising_gas_br]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.search_advertising_gas_ca]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.search_advertising_gas_de]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.search_advertising_gas_es]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.search_advertising_gas_fr]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.search_advertising_gas_it]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.search_advertising_gas_mx]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.search_advertising_gas_uk]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.search_advertising_gas_us]: DCAInstructionsAmazonAdvertising,
};

const disableAccountNameEntry: ReadonlyArray<OfferedSubscriptionIdEnum> = [];

export const authorize = (dca: DataCollectionAccountWithSettings): undefined | Error => {
  const dcaCountryCode = getCountryCodeFromDCA(dca) as CountryCode;
  if (!dcaCountryCode) {
    return new Error('Country Code not found');
  }
  const authUrl = getAuthUrl(dcaCountryCode);
  if (!authUrl) {
    return new Error('Auth URL not found');
  }
  return authorizeAmazonAdvertisingApp(dca, authUrl);
};

export const hasRequiredSettings = (
  dca: DataCollectionAccountWithSettings,
): boolean => dca.offered_subscription_id in SubscriptionSettingUniqueValues
  && !dca.settings.some(
    ({ key }) => key === '__oauth_grant_code',
  );

export default createDataCollectionConfig<OfferedSubscriptionIdEnum>(
  OfferedSubscriptionIdEnum,
  VisibleOfferedSubscriptions,
  SubscriptionQueryTranslations,
  SubscriptionSettingUniqueValues,
  disableAccountNameEntry,
  authorize,
  hasRequiredSettings,
  dcaInstructionsComponent,
);

import { v4 as genUUIDV4 } from 'uuid';
import GlobalConfig from '../../app/config';
import { DataCollectionAccountWithSettings, StoredRequestState } from '../../controllers/subscription-service/types';
import sendRumError from '../datadogRum';
import { generateOAuthLocalKey } from '../stringFormatting';

export const configAmazonSPAPIParams = (
  dca: DataCollectionAccountWithSettings,
) => {
  const requestParams = new URLSearchParams();
  const newRequestId = genUUIDV4();
  const storedRequestState: StoredRequestState = {
    dca,
    timestamp: Date.now(),
  };

  localStorage.setItem(generateOAuthLocalKey(newRequestId), JSON.stringify(storedRequestState));
  const redirectUrl = new URL(`${window.origin}/organizationAdmin`);
  requestParams.set('state', newRequestId);
  requestParams.set('application_id', GlobalConfig.config.AMAZON_APPLICATION_ID);
  requestParams.set('redirect_uri', redirectUrl.toString());
  if (GlobalConfig.config.ENV !== 'production') {
    requestParams.set('version', 'beta');
  }
  return requestParams;
};

export const configAmazonAdvertisingParams = (
  dca: DataCollectionAccountWithSettings,
) => {
  const requestParams = new URLSearchParams();
  const newRequestId = genUUIDV4();
  const storedRequestState: StoredRequestState = {
    dca,
    timestamp: Date.now(),
  };

  // https://advertising.amazon.com/API/docs/en-us/guides/account-management/authorization/authorization-grants
  localStorage.setItem(generateOAuthLocalKey(newRequestId), JSON.stringify(storedRequestState));
  const redirectUrl = new URL(`${window.origin}/organizationAdmin`);
  requestParams.set('client_id', GlobalConfig.config.AMAZON_ADVERTISING_LWA_CLIENT_ID);
  requestParams.set('scope', 'advertising::campaign_management');
  requestParams.set('response_type', 'code');
  requestParams.set('redirect_uri', redirectUrl.toString());
  requestParams.set('state', newRequestId);

  return requestParams;
};

const findDCAAttribute = (
  dca: DataCollectionAccountWithSettings,
  setting_key: string,
  defaultValue: string | string[],
) => {
  const setting = dca.settings.find(
    ({ key }) => key === setting_key,
  );
  return setting ? setting.value : defaultValue;
};

// for amazon sp-api
export const getMarketPlacesFromDCA = (dca: DataCollectionAccountWithSettings): string[] => {
  let marketplaceCodes = findDCAAttribute(dca, 'marketplace_country_codes', ['US']);

  // Expecting a list of strings.. if I'm getting a complex object, wipe and reset it.
  if (!Array.isArray(marketplaceCodes) && typeof marketplaceCodes === 'object') {
    marketplaceCodes = [];
  }
  return Array.isArray(marketplaceCodes) ? marketplaceCodes : [marketplaceCodes];
};

// for amazon-advertising
export const getCountryCodeFromDCA = (dca: DataCollectionAccountWithSettings): string => {
  let countryCode = findDCAAttribute(dca, 'country_code', 'US');

  if (Array.isArray(countryCode) || typeof countryCode === 'object') {
    countryCode = 'US';
  }
  return countryCode;
};

export const authorizeAmazonApp = (
  dca: DataCollectionAccountWithSettings,
  authUrl: URL | undefined,
): undefined | Error => {
  const params = configAmazonSPAPIParams(dca);

  if (!authUrl) {
    const authorizationError = new Error('Unable to locate authorization for marketplace.', {
      cause: `No authUrl found for dca ${JSON.stringify(dca)}`,
    });
    sendRumError(authorizationError);
    return authorizationError;
  }
  const formatedUrl = new URL(authUrl.toString());

  formatedUrl.search = params.toString();
  formatedUrl.protocol = 'https:';

  window.open(formatedUrl, '_self');
  return undefined;
};

export const authorizeAmazonAdvertisingApp = (
  dca: DataCollectionAccountWithSettings,
  authUrl: URL | undefined,
): undefined | Error => {
  const params = configAmazonAdvertisingParams(dca);

  if (!authUrl) {
    const authorizationError = new Error('Unable to locate authorization for country code.', {
      cause: `No authUrl found for dca ${JSON.stringify(dca)}`,
    });
    sendRumError(authorizationError);
    return authorizationError;
  }
  const formatedUrl = new URL(authUrl.toString());

  formatedUrl.search = params.toString();
  formatedUrl.protocol = 'https:';

  window.open(formatedUrl, '_self');
  return undefined;
};

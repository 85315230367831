import { v4 as genUUIDV4 } from 'uuid';
import GlobalConfig from '../../app/config';
import DCAInstructionsCriteoRetailMedia from '../../components/DCAInstructions/DCAInstructionsCriteoRetailMedia';
import { DataCollectionAccountWithSettings, StoredRequestState } from '../../controllers/subscription-service/types';
import { generateOAuthLocalKey } from '../stringFormatting';
import createDataCollectionConfig from './types';

enum OfferedSubscriptionIdEnum {
  criteo_retail_media_bestbuy = '7be1e959-2e1e-4d29-a109-6957933ead35',
  criteo_retail_media_lowes = '0bab3019-d8c9-4774-91c0-d01572323a9e',
  criteo_retail_media_macys = 'b77bbb94-d807-40dc-9e16-f88ae7f51eaf',
  criteo_retail_media_target = '886cb5c4-ea08-4bc5-a8af-545eb15c9d11',
}

// const marketplaces = ['BESTBUY', 'LOWES', 'MACYS', 'TARGET'] as const;
// type Marketplace = typeof marketplaces[number];

const VisibleOfferedSubscriptions: ReadonlyArray<OfferedSubscriptionIdEnum> = [
  OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy,
  OfferedSubscriptionIdEnum.criteo_retail_media_lowes,
  OfferedSubscriptionIdEnum.criteo_retail_media_macys,
  OfferedSubscriptionIdEnum.criteo_retail_media_target,
];

const SubscriptionQueryTranslations: Readonly<
Record<OfferedSubscriptionIdEnum, Record<string, string>>
> = {
  [OfferedSubscriptionIdEnum.criteo_retail_media_target]: {
    code: '__criteo_oauth_code',
  },
  [OfferedSubscriptionIdEnum.criteo_retail_media_lowes]: {
    code: '__criteo_oauth_code',
  },
  [OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy]: {
    code: '__criteo_oauth_code',
  },
  [OfferedSubscriptionIdEnum.criteo_retail_media_macys]: {
    code: '__criteo_oauth_code',
  },
};

const SubscriptionSettingUniqueValues: Readonly<Record<OfferedSubscriptionIdEnum, string[]>> = {
  [OfferedSubscriptionIdEnum.criteo_retail_media_target]: [
    'account_name',
  ],
  [OfferedSubscriptionIdEnum.criteo_retail_media_lowes]: [
    'account_name',
  ],
  [OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy]: [
    'account_name',
  ],
  [OfferedSubscriptionIdEnum.criteo_retail_media_macys]: [
    'account_name',
  ],
};

const authUrl = 'https://consent.criteo.com/request';

const disableAccountNameEntry: ReadonlyArray<OfferedSubscriptionIdEnum> = [
  OfferedSubscriptionIdEnum.criteo_retail_media_target,
  OfferedSubscriptionIdEnum.criteo_retail_media_lowes,
  OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy,
  OfferedSubscriptionIdEnum.criteo_retail_media_macys,
];

const dcaInstructionsComponent: Record<OfferedSubscriptionIdEnum, React.FC | JSX.Element> = {
  [OfferedSubscriptionIdEnum.criteo_retail_media_target]: DCAInstructionsCriteoRetailMedia,
  [OfferedSubscriptionIdEnum.criteo_retail_media_lowes]: DCAInstructionsCriteoRetailMedia,
  [OfferedSubscriptionIdEnum.criteo_retail_media_bestbuy]: DCAInstructionsCriteoRetailMedia,
  [OfferedSubscriptionIdEnum.criteo_retail_media_macys]: DCAInstructionsCriteoRetailMedia,
};

const configCriteoParams = (
  dca: DataCollectionAccountWithSettings,
) => {
  const requestParams = new URLSearchParams();
  const newRequestId = genUUIDV4();
  const storedRequestState: StoredRequestState = {
    dca,
    timestamp: Date.now(),
  };

  localStorage.setItem(generateOAuthLocalKey(newRequestId), JSON.stringify(storedRequestState));
  const redirectUrl = new URL(`${window.origin}/organizationAdmin`);
  requestParams.set('response_type', 'code');
  requestParams.set('state', newRequestId);
  requestParams.set('client_id', GlobalConfig.config.CRITEO_CLIENT_ID);
  requestParams.set('redirect_uri', redirectUrl.toString());

  return requestParams;
};

export const authorize = (
  dca: DataCollectionAccountWithSettings,
) => {
  const params = configCriteoParams(dca);

  const criteoUrl = new URL(authUrl);
  criteoUrl.search = params.toString();
  window.open(criteoUrl, '_self');
};

export const hasRequiredSettings = (
  dca: DataCollectionAccountWithSettings,
): boolean => dca.offered_subscription_id in SubscriptionSettingUniqueValues
  && !dca.settings.some(
    ({ key }) => key === '__spapi_oauth_code',
  );

export default createDataCollectionConfig<OfferedSubscriptionIdEnum>(
  OfferedSubscriptionIdEnum,
  VisibleOfferedSubscriptions,
  SubscriptionQueryTranslations,
  SubscriptionSettingUniqueValues,
  disableAccountNameEntry,
  authorize,
  hasRequiredSettings,
  dcaInstructionsComponent,
);

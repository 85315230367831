import { DataCollectionAccountWithSettings } from '../../controllers/subscription-service/types';

export interface DataCollectionConfig<OfferedSubscriptionId extends string> {
  offeredSubscriptionEnum: Record<string, OfferedSubscriptionId>;
  visibleOfferedSubscriptions: ReadonlyArray<OfferedSubscriptionId>
  subscriptionQueryTranslations: Readonly<
  Partial<Record<OfferedSubscriptionId, Record<string, string>>>
  >,
  SubscriptionSettingUniqueValues: Readonly<Partial<Record<OfferedSubscriptionId, string[]>>>,
  disableAccountNameEntry: ReadonlyArray<OfferedSubscriptionId>,
  authorize: (dca: DataCollectionAccountWithSettings) => undefined | void | Error,
  hasRequiredSettings: (dca: DataCollectionAccountWithSettings) => boolean,
  dcaInstructionsComponent: Record<OfferedSubscriptionId, React.FC | JSX.Element>
}

/* istanbul ignore next */
export default function createDataCollectionConfig<
  OfferedSubscriptionId extends string,
>(
  offeredSubscriptionEnum: Record<string, OfferedSubscriptionId>,
  visibleOfferedSubscriptions: ReadonlyArray<OfferedSubscriptionId>,
  subscriptionQueryTranslations: Readonly<
  Partial<Record<OfferedSubscriptionId, Record<string, string>>>
  >,
  SubscriptionSettingUniqueValues: Readonly<Partial<Record<OfferedSubscriptionId, string[]>>>,
  disableAccountNameEntry: ReadonlyArray<OfferedSubscriptionId>,
  authorize: (dca: DataCollectionAccountWithSettings) => undefined | void | Error,
  hasRequiredSettings: (dca: DataCollectionAccountWithSettings) => boolean,
  dcaInstructionsComponent: Record<OfferedSubscriptionId, React.FC | JSX.Element>,
): DataCollectionConfig<OfferedSubscriptionId> {
  return {
    offeredSubscriptionEnum,
    visibleOfferedSubscriptions,
    subscriptionQueryTranslations,
    SubscriptionSettingUniqueValues,
    disableAccountNameEntry,
    authorize,
    hasRequiredSettings,
    dcaInstructionsComponent,
  };
}

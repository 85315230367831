import React, { ReactElement } from 'react';

export type RetailerUrlFormat = {
  retailer_home: string;
  retailer_name: string;
  retailer_db_name: string;
  requirements: ReactElement[];
  examples: ReactElement[];
};

export const retailerUrlFormats: RetailerUrlFormat[] = [
  {
    retailer_home: 'academy.com',
    retailer_name: 'Academy',
    retailer_db_name: 'Academy',
    requirements: [
      (
        <li key="academy-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
    ],
    examples: [
      (
        <li key="academy-example-1">
          <span className="example-url">
            https://www.academy.com/p/&#91;product info&#93;
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'acehardware.com',
    retailer_name: 'Ace Hardware',
    retailer_db_name: 'Ace Hardware',
    requirements: [
      (
        <li key="acehardware-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="acehardware-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may contain some product information.
        </li>
      ),
    ],
    examples: [
      (
        <li key="acehardware-example-1">
          <span className="example-url">
            https://www.acehardware.com/departments/outdoor-living/grills-and-smokers/gas-grills/8863581
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'shop.advanceautoparts.com',
    retailer_name: 'Advance Auto Parts',
    retailer_db_name: 'Advance Auto Parts',
    requirements: [
      (
        <li key="advanceauto-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="advanceauto-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may contain some product information.
        </li>
      ),
      (
        <li key="advanceauto-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://shop.advanceautoparts.com/p/
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="advanceauto-example-1">
          <span className="example-url">
            https://shop.advanceautoparts.com/p/diehard-gold-battery-35-group-size-640-cca-800-ca-100-minute-reserve-capacity-maximum-starting-power-35-2/2130004-P
          </span>
        </li>
      ),
      (
        <li key="advanceauto-example-2">
          <span className="example-url">
            https://shop.advanceautoparts.com/p/x/2130004-P
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.ae',
    retailer_name: 'Amazon AE',
    retailer_db_name: 'Amazon.ae',
    requirements: [
      (
        <li key="amazonae-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazonae-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazonae-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazonae-example-1">
          <span className="example-url">
            https://www.amazon.ae/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazonae-example-1">
          <span className="example-url">
            https://www.amazon.ae/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.ca',
    retailer_name: 'Amazon CA',
    retailer_db_name: 'Amazon.ca',
    requirements: [
      (
        <li key="amazonca-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazonca-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazonca-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazonca-example-1">
          <span className="example-url">
            https://www.amazon.ca/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazonca-example-1">
          <span className="example-url">
            https://www.amazon.ca/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.co.uk',
    retailer_name: 'Amazon UK',
    retailer_db_name: 'Amazon.co.uk',
    requirements: [
      (
        <li key="amazoncouk-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazoncouk-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazoncouk-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazoncouk-example-1">
          <span className="example-url">
            https://www.amazon.co.uk/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazoncouk-example-1">
          <span className="example-url">
            https://www.amazon.co.uk/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.com',
    retailer_name: 'Amazon US',
    retailer_db_name: 'Amazon.com',
    requirements: [
      (
        <li key="amazon-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazon-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazon-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazon-example-1">
          <span className="example-url">
            https://www.amazon.com/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazon-example-2">
          <span className="example-url">
            https://www.amazon.com/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.com.br',
    retailer_name: 'Amazon BR',
    retailer_db_name: 'Amazon.com.br',
    requirements: [
      (
        <li key="amazoncombr-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazoncombr-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazoncombr-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazoncombr-example-1">
          <span className="example-url">
            https://www.amazon.com.br/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazoncombr-example-2">
          <span className="example-url">
            https://www.amazon.com.br/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.com.mx',
    retailer_name: 'Amazon MX',
    retailer_db_name: 'Amazon.com.mx',
    requirements: [
      (
        <li key="amazoncommx-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazoncommx-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazoncommx-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazoncommx-example-1">
          <span className="example-url">
            https://www.amazon.com.br/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazoncommx-example-1">
          <span className="example-url">
            https://www.amazon.com.br/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.de',
    retailer_name: 'Amazon DE',
    retailer_db_name: 'Amazon.de',
    requirements: [
      (
        <li key="amazonde-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazonde-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazonde-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazonde-example-1">
          <span className="example-url">
            https://www.amazon.de/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazonde-example-1">
          <span className="example-url">
            https://www.amazon.de/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.es',
    retailer_name: 'Amazon ES',
    retailer_db_name: 'Amazon.es',
    requirements: [
      (
        <li key="amazones-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazones-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazones-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazones-example-1">
          <span className="example-url">
            https://www.amazon.es/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazones-example-1">
          <span className="example-url">
            https://www.amazon.es/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.fr',
    retailer_name: 'Amazon FR',
    retailer_db_name: 'Amazon.fr',
    requirements: [
      (
        <li key="amazonfr-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazonfr-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazonfr-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazonfr-example-1">
          <span className="example-url">
            https://www.amazon.fr/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazonfr-example-1">
          <span className="example-url">
            https://www.amazon.fr/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'amazon.it',
    retailer_name: 'Amazon IT',
    retailer_db_name: 'Amazon.it',
    requirements: [
      (
        <li key="amazonit-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the ASIN.
        </li>
      ),
      (
        <li key="amazonit-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have the tracking information removed.
        </li>
      ),
      (
        <li key="amazonit-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1
          </span>
          &nbsp;
          if this is a non-variated product.  Otherwise it must end with
          &nbsp;
          <span className="url-fragment">
            ?th=1&amp;psc=1
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="amazonit-example-1">
          <span className="example-url">
            https://www.amazon.it/dp/B0B33TZX29?th=1
          </span>
        </li>
      ),
      (
        <li key="amazonit-example-1">
          <span className="example-url">
            https://www.amazon.it/dp/B0CL4NC1H5?th=1&psc=1
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'autozone.com',
    retailer_name: 'Autozone',
    retailer_db_name: 'Autozone',
    requirements: [
      (
        <li key="autozone-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="autozone-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may contain some product information.
        </li>
      ),
      (
        <li key="autozone-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://www.advanceautoparts.com/
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="autozone-example-1">
          <span className="example-url">
            https://shop.advanceautoparts.com/p/diehard-gold-battery-35-group-size-640-cca-800-ca-100-minute-reserve-capacity-maximum-starting-power-35-2/2130004-P
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'bedbathandbeyond.com',
    retailer_name: 'Bed Bath & Beyond',
    retailer_db_name: 'Bed Bath and Beyond',
    requirements: [
      (
        <li key="bedbathandbeyond-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will not contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="bedbathandbeyond-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="url-fragment">
            opre=1
          </span>
          &nbsp;
          as the first set of `query` text after
          &nbsp;
          <span className="url-fragment">
            product.html
          </span>
          .  The first query must have a
          &nbsp;
          <span className="url-fragment">
            ?
          </span>
          &nbsp;
          preceding it.
          All others should have an
          &nbsp;
          <span className="url-fragment">
            &amp;
          </span>
          &nbsp;
          before them instead.
        </li>
      ),
      (
        <li key="bedbathandbeyond-requirement-4">
          If the item is a variant, its
          &nbsp;
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must have one or more
          &nbsp;
          <span className="url-fragment">
            option=[8 digit #]
          </span>
          &nbsp;
          attached to the end of it.
          The first query must have a
          &nbsp;
          <span className="url-fragment">
            ?
          </span>
          &nbsp;
          preceding it.
          All others should have an
          &nbsp;
          <span className="url-fragment">
            &amp;
          </span>
          &nbsp;
          before them instead.
        </li>
      ),
    ],
    examples: [
      (
        <li key="bedbathandbeyond-example-1">
          Non-variant:
          &nbsp;
          <span className="example-url">
            https://www.bedbathandbeyond.com/Home-Garden/Round-Style-Run-In-Shelter/16695949/product.html?opre=1
          </span>
        </li>
      ),
      (
        <li key="bedbathandbeyond-example-2">
          Non-variant:
          &nbsp;
          <span className="example-url">
            https://www.bedbathandbeyond.com/16695949/product.html?opre=1
          </span>
        </li>
      ),
      (
        <li key="bedbathandbeyond-example-3">
          Variant:
          &nbsp;
          <span className="example-url">
            https://www.bedbathandbeyond.com/Home-Garden/TEMPUR-Ergo-Adjustable-Base/22643828/product.html?opre=1&option=37706610
          </span>
        </li>
      ),
      (
        <li key="bedbathandbeyond-example-4">
          Variant:
          &nbsp;
          <span className="example-url">
            https://www.bedbathandbeyond.com/Home-Garden/TEMPUR-Ergo-Adjustable-Base/22643828/product.html?opre=1&option=37706610&option=12345678
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'bestbuy.com',
    retailer_name: 'Best Buy',
    retailer_db_name: 'Best Buy',
    requirements: [
      (
        <li key="bestbuy-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must always contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="bestbuy-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            .p?skuId=[Retailer SKU]
          </span>
          .
        </li>
      ),
      (
        <li key="bestbuy-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          can be shortened.
        </li>
      ),
    ],
    examples: [
      (
        <li key="bestbuy-example-1">
          Short Version:
          &nbsp;
          <span className="example-url">
            https://www.bestbuy.com/site/8579341.p?skuId=8579341
          </span>
        </li>
      ),
      (
        <li key="bestbuy-example-2">
          Long Version:
          &nbsp;
          <span className="example-url">
            https://www.bestbuy.com/site/whirlpool-4-3-cu-ft-high-efficiency-top-load-washer-with-smooth-wave-stainless-steel-wash-basket-white/8579341.p?skuId=8579341
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'biglots.com',
    retailer_name: 'Big Lots',
    retailer_db_name: 'Big Lots',
    requirements: [
      (
        <li key="biglots-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will sometimes contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="biglots-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
    ],
    examples: [
      (
        <li key="biglots-example-1">
          <span className="example-url">
            https://www.biglots.com/product/memory-foam-gel-standard-pillow/p810436274
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'bjs.com',
    retailer_name: 'BJ\'s',
    retailer_db_name: 'BJs',
    requirements: [
      (
        <li key="bjs-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may not contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="bjs-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
    ],
    examples: [
      (
        <li key="bjs-example-1">
          <span className="example-url">
            https://www.bjs.com/product/sealy-duochill-cooling-memory-foam-bed-pillow/3000000000003077819
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'costco.com',
    retailer_name: 'Costco',
    retailer_db_name: 'Costco',
    requirements: [
      (
        <li key="costco-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="costco-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may contain product information.
        </li>
      ),
      (
        <li key="costco-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            .html
          </span>
          .
        </li>
      ),
      (
        <li key="costco-requirement-4">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          can be shortened.
        </li>
      ),
    ],
    examples: [
      (
        <li key="costco-example-1">
          Short Version:
          &nbsp;
          <span className="example-url">
            https://www.costco.com/.product.1364112.html
          </span>
        </li>
      ),
      (
        <li key="costco-example-2">
          Long Version:
          &nbsp;
          <span className="example-url">
            https://www.costco.com/cocoon-by-sealy-10-medium-memory-foam-mattress.product.1364112.html
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'costcobusinessdelivery.com',
    retailer_name: 'Costco Business Delivery',
    retailer_db_name: 'Costco Business Delivery',
    requirements: [
      (
        <li key="costcobusinessdeliverycom-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="costcobusinessdeliverycom-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may contain product information.
        </li>
      ),
      (
        <li key="costcobusinessdeliverycom-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            .html
          </span>
          .
        </li>
      ),
      (
        <li key="costcobusinessdeliverycom-requirement-4">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          can be shortened.
        </li>
      ),
    ],
    examples: [
      (
        <li key="costcobusinessdeliverycom-example-1">
          Short Version:
          &nbsp;
          <span className="example-url">
            https://www.costcobusinessdelivery.com/.product.100376347.html
          </span>
        </li>
      ),
      (
        <li key="costcobusinessdeliverycom-example-2">
          Long Version:
          &nbsp;
          <span className="example-url">
            https://www.costcobusinessdelivery.com/kirkland-signature-whole-milk%2c-1-gallon%2c-2-ct.product.10178010.html
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'cubcadet.com',
    retailer_name: 'Cub Cadet',
    retailer_db_name: 'Cub Cadet',
    requirements: [
      (
        <li key="cubcadet-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="cubcadet-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="cubcadet-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            .html
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="cubcadet-example-1">
          <span className="example-url">
            https://www.cubcadet.com/en_US/zero-turn-mowers/z-force-sx-54/47RIDGJC010.html
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'doitbest.com',
    retailer_name: 'Do It Best',
    retailer_db_name: 'Do-it-Best',
    requirements: [
      (
        <li key="doitbest-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="doitbest-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="doitbest-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?SKU=[Retailer SKU]
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="doitbest-example-1">
          <span className="example-url">
            https://www.doitbest.com/shop/heating-ventilation-and-air-conditioning/dryer-range-and-bath-vents/vent-hoods-and-caps/roof-vent-cap/broan-nutone-8-in-black-steel-back-draft-damper-roof-vent-cap?SKU=265162
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'farmandfleet.com',
    retailer_name: 'Farm and Fleet',
    retailer_db_name: 'Blains Farm and Fleet',
    requirements: [
      (
        <li key="farmandfleet-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="farmandfleet-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="farmandfleet-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            .html
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="farmandfleet-example-1">
          <span className="example-url">
            https://www.farmandfleet.com/products/
            <b>1007144</b>
            -oklahoma-joes-highland-reverse-flow-smoker.html
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'fingerhut.com',
    retailer_name: 'Fingerhut',
    retailer_db_name: 'Fingerhut',
    requirements: [
      (
        <li key="fingerhut-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="fingerhut-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?q=[Retailer SKU]
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="fingerhut-example-1">
          <span className="example-url">
            https://www.fingerhut.com/product/F2660.uts?q=F2660
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'fleetfarm.com',
    retailer_name: 'Fleet Farm',
    retailer_db_name: 'Fleet Farm',
    requirements: [
      (
        <li key="fleetfarm-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          does not need to contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="fleetfarm-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product info.
        </li>
      ),
      (
        <li key="fleetfarm-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          cannot be shortened.
        </li>
      ),
    ],
    examples: [
      (
        <li key="fleetfarm-example-1">
          <span className="example-url">
            https://www.fleetfarm.com/detail/char-broil-20-in-universal-liquid-propane-grill-hose-regulator/0000000061603
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'grainger.com',
    retailer_name: 'Grainger',
    retailer_db_name: 'Grainger',
    requirements: [
      (
        <li key="grainger-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="grainger-example-1">
          <span className="example-url">
            https://www.grainger.com/product/SUNCAST-Deck-Box-73-gal-
            <b>15X398</b>
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'homedepot.com',
    retailer_name: 'The Home Depot',
    retailer_db_name: 'Home Depot',
    requirements: [
      (
        <li key="homedepot-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="homedepot-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may contain some product information.
        </li>
      ),
    ],
    examples: [
      (
        <li key="homedepot-example-1">
          <span className="example-url">
            https://www.homedepot.com/p/First-America-PTAC-Wall-Sleeve-Trim-Kit-SLEEVEMOLDING/206634991
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'hdsupplysolutions.com',
    retailer_name: 'HD Supply',
    retailer_db_name: 'HD Supply',
    requirements: [
      (
        <li key="hdsupplysolutionscom-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://hdsupplysolutions.com/p/
          </span>
        </li>
      ),
      (
        <li key="hdsupplysolutionscom-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may contain some product information.
        </li>
      ),
    ],
    examples: [
      (
        <li key="hdsupplysolutionscom-example-1">
          <span className="example-url">
            https://hdsupplysolutions.com/p/televisions-accessories-00-35-30/rca-40-in-1080p-pro-idiom-hospitality-tv-p220952
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'kohls.com',
    retailer_name: 'Kohls',
    retailer_db_name: 'Kohls',
    requirements: [
      (
        <li key="kohls-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="kohls-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="kohls-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must always end with
          &nbsp;
          <span className="url-fragment">
            ?skuId=[Retailer SKU]
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="kohls-example-1">
          <span className="example-url">
            https://www.kohls.com/product/prd-1811976/suncast-100-ft-garden-hose-reel-hideaway-outdoor.jsp?skuId=
            <b>95521772</b>
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'lowes.com',
    retailer_name: 'Lowe\'s',
    retailer_db_name: 'Lowe\'s',
    requirements: [
      (
        <li key="lowes-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information, followed by a
          &nbsp;
          <span className="url-fragment">
            /
          </span>
          &nbsp;
          and a 6-10 digit number.
        </li>
      ),
    ],
    examples: [
      (
        <li key="lowes-example-1">
          <span className="example-url">
            https://www.lowes.com/pd/CRAFTSMAN-SB210-21-in-123-cc-Single-Stage-Gas-Snow-Blower-with-Pull-Start/1000602799
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'meijer.com',
    retailer_name: 'Meijer',
    retailer_db_name: 'Meijer',
    requirements: [
      (
        <li key="meijer-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://www.meijer.com/shopping/product
          </span>
        </li>
      ),
      (
        <li key="meijer-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information, followed by a
          &nbsp;
          <span className="url-fragment">
            /
          </span>
          &nbsp;
          and a number.
        </li>
      ),
      (
        <li key="meijer-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            .html
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="meijer-example-1">
          <span className="example-url">
            https://www.meijer.com/shopping/product/samsung-65-uhd-smart-tv/88727683078.html
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'menards.com',
    retailer_name: 'Menards',
    retailer_db_name: 'Menards',
    requirements: [
      (
        <li key="menards-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will not contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          , it will contain an internet identification string instead.
        </li>
      ),
      (
        <li key="menards-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may contain product information.
        </li>
      ),
      (
        <li key="menards-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          can be shortened.
        </li>
      ),
    ],
    examples: [
      (
        <li key="menards-example-1">
          Short Version:
          &nbsp;
          <span className="example-url">
            https://www.menards.com/main/p-1444441253821-c-10109.htm
          </span>
        </li>
      ),
      (
        <li key="menards-example-2">
          Long Version:
          &nbsp;
          <span className="example-url">
            https://www.menards.com/main/tools-hardware/outdoor-power-equipment/chainsaws-accessories/remington-reg-16-12-amp-electric-chainsaw/p-1444441253821-c-10109.htm
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'napaonline.com',
    retailer_name: 'Napa',
    retailer_db_name: 'Napa',
    requirements: [
      (
        <li key="napaonline-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="napaonline-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will not contain product information.
        </li>
      ),
      (
        <li key="napaonline-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://www.napaonline.com/en/
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="napaonline-example-1">
          <span className="example-url">
            https://www.napaonline.com/en/p/NAFDEF002
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'officedepot.com',
    retailer_name: 'Office Depot',
    retailer_db_name: 'Office Depot',
    requirements: [
      (
        <li key="officedepot-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="officedepot-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product and brand information.
        </li>
      ),
      (
        <li key="officedepot-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://www.officedepot.com/a/products/
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="officedepot-example-1">
          <span className="example-url">
            https://www.officedepot.com/a/products/9197728/Epson-EcoTank-ET-3850-All-in/
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'oreillyauto.com',
    retailer_name: 'O\'Reilly',
    retailer_db_name: 'O\'Reilly',
    requirements: [
      (
        <li key="oreillyauto-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will not contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="oreillyauto-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product and brand information.
        </li>
      ),
      (
        <li key="oreillyauto-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://www.oreillyauto.com/detail/c/
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="oreillyauto-example-1">
          <span className="example-url">
            https://www.oreillyauto.com/detail/c/bluedef/bluedef-2-5-gallon-diesel-exhaust-fluid/def0/25gal
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'overstock.com',
    retailer_name: 'Overstock',
    retailer_db_name: 'Overstock',
    requirements: [
      (
        <li key="overstockcom-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="overstockcom-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product and brand information.
        </li>
      ),
      (
        <li key="overstockcom-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://www.overstock.com/products/
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="overstockcom-example-1">
          <span className="example-url">
            https://www.overstock.com/products/veronica-arc-floor-lamp-32985670
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'partstree.com',
    retailer_name: 'Parts Tree',
    retailer_db_name: 'Parts Tree',
    requirements: [
      (
        <li key="partstree-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain and end with the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="powerequipmentdirect-example-1">
          <span className="example-url">
            https://www.partstree.com/parts/cub-cadet-19a70001100
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'powerequipmentdirect.com',
    retailer_name: 'Power Equipment Direct',
    retailer_db_name: 'Power Equipment Direct',
    requirements: [
      (
        <li key="powerequipmentdirect-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="powerequipmentdirect-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            [Retailer SKU].html
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="powerequipmentdirect-example-1">
          <span className="example-url">
            https://www.powerequipmentdirect.com/Cub-Cadet-13A221JD010/p62654.html
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'samsclub.com',
    retailer_name: 'Sam\'s Club',
    retailer_db_name: 'Sams Club',
    requirements: [
      (
        <li key="samsclub-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will not contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="samsclub-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="samsclub-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may end with
          &nbsp;
          <span className="url-fragment">
            ?xid=plp_product_[1 digit #]
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="samsclub-example-1">
          <span className="example-url">
            https://www.samsclub.com/p/pro-adapt-f-kg-set-king-set/prod22820299?xid=plp_product_1
          </span>
        </li>
      ),
      (
        <li key="samsclub-example-2">
          <span className="example-url">
            https://www.samsclub.com/p/pro-adapt-f-kg-set-king-set/prod22820299
          </span>
        </li>
      ),
      (
        <li key="samsclub-example-3">
          <span className="example-url">
            https://www.samsclub.com/p/sealy-posturepedic-spring-fulton-eurotop-medium-feel-mattress/P03010356
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'staples.com',
    retailer_name: 'Staples',
    retailer_db_name: 'Staples',
    requirements: [
      (
        <li key="staplescom-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://www.staples.com/product_
          </span>
        </li>
      ),
      (
        <li key="staplescom-requirement-2">
          must end with retailer SKU
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="staplescom-example-1">
          <span className="example-url">
            https://www.staples.com/product_24527313

          </span>
        </li>
      ),
      (
        <li key="staplescom-example-2">
          <span className="example-url">
            https://www.staples.com/product_24527313

          </span>
        </li>
      ),
      (
        <li key="staplescom-example-3">
          <span className="example-url">
            https://www.staples.com/hp-smart-tank-7602-inkjet-printer-all-in-one-supertank-print-copy-scan-fax-28b98a/product_24527313

          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'supplyhouse.com',
    retailer_name: 'Supply House',
    retailer_db_name: 'Supply House',
    requirements: [
      (
        <li key="supplyhousecom-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
    ],
    examples: [
      (
        <li key="supplyhousecom-example-1">
          <span className="example-url">
            https://www.supplyhouse.com/Honeywell-Home-Resideo-TH6220WF2006-T6-Pro-Smart-Wi-Fi-Programmable-Thermostat-2H-2C
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'target.com',
    retailer_name: 'Target',
    retailer_db_name: 'Target',
    requirements: [
      (
        <li key="target-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will not contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          &nbsp;
          but rather the
          &nbsp;
          <b>TCIN</b>
          .  This can be found on the retailer page under
          &nbsp;
          <span className="url-fragment">
            Specifications -&gt; TCIN
          </span>
        </li>
      ),
      (
        <li key="target-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may contain product information.
        </li>
      ),
      (
        <li key="target-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          but can be shortened.
        </li>
      ),
    ],
    examples: [
      (
        <li key="target-example-1">
          Short Version:
          &nbsp;
          <span className="example-url">
            https://www.target.com/p/-/A-54150247
          </span>
        </li>
      ),
      (
        <li key="target-example-2">
          Long Version:
          &nbsp;
          <span className="example-url">
            https://www.target.com/p/huffy-ladies-nassau-26-cruiser-bike-teal/-/A-54150247
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'troybilt.com',
    retailer_name: 'Troy-Bilt',
    retailer_db_name: 'Troy-Bilt',
    requirements: [],
    examples: [
      (
        <li key="troybilt-example-1">
          <span className="example-url">
            https://www.troybilt.com/en_US/prior-year-models/flex-wide-area-mower-attachment/23AAAA8X766.html
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'tractorsupply.com',
    retailer_name: 'Tractor Supply',
    retailer_db_name: 'Tractor Supply',
    requirements: [
      (
        <li key="tractorsupply-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will sometimes contain the
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="tractorsupply-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="tractorsupply-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          may end with
          &nbsp;
          <span className="url-fragment">
            -[Retailer SKU]
          </span>
          .
        </li>
      ),
    ],
    examples: [
      (
        <li key="tractorsupply-example-1">
          <span className="example-url">
            https://www.tractorsupply.com/tsc/product/shelterlogic-automatic-shelter-vent-kit-pack-of-2-1069603
          </span>
        </li>
      ),
      (
        <li key="tractorsupply-example-2">
          <span className="example-url">
            https://www.tractorsupply.com/tsc/product/shelterlogic-10-ft-x-10-ft-sport-pop-up-canopy-slant-leg
          </span>
        </li>
      ),
    ],
  },

  {
    retailer_home: 'truevalue.com',
    retailer_name: 'True Value',
    retailer_db_name: 'True Value',
    requirements: [
      (
        <li key="truevalue-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will not contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="truevalue-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must start with
          &nbsp;
          <span className="url-fragment">
            https://www.truevalue.com/product/
          </span>
        </li>
      ),
      (
        <li key="truevalue-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="truevalue-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with a trailing slash (
          <span className="url-fragment">
            /
          </span>
          )
        </li>
      ),
    ],
    examples: [
      (
        <li key="truevalue-example-1">
          <span className="example-url">
            https://www.truevalue.com/product/150-ft-x-5-8-inch-hose-hangout-wall-mount-hose-hanger/
          </span>
        </li>

      ),
      (
        <li key="truevalue-example-2">
          <span className="example-url">
            https://www.truevalue.com/product/roof-cap-steel-black-finish-fits-3-or-4-in-round-duct/
          </span>
        </li>

      ),
    ],
  },
  {
    retailer_home: 'walmart.ca',
    retailer_name: 'Walmart CA',
    retailer_db_name: 'Walmart - Canada',
    requirements: [
      (
        <li key="walmartca-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          will not contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="walmartca-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
    ],
    examples: [
      (
        <li key="walmartca-example-1">
          <span className="example-url">
            https://www.walmart.ca/en/ip/rollplay-6v-mini-scooter/6000191047081
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'walmart.com',
    retailer_name: 'Walmart US',
    retailer_db_name: 'Walmart',
    requirements: [
      (
        <li key="walmart-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          can contain product information, but does not need to.
        </li>
      ),
      (
        <li key="walmart-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          s for this retailer can be shortened.
        </li>
      ),
    ],
    examples: [
      (
        <li key="walmart-example-1">
          Short Version:
          &nbsp;
          <span className="example-url">
            https://www.walmart.com/ip/seort/252151838?selected=true
          </span>
        </li>
      ),
      (
        <li key="walmart-example-2">
          Long Version:
          &nbsp;
          <span className="example-url">
            https://www.walmart.com/ip/Design-House-3-Pack-4-in-x-5-8-in-Radius-Oil-Rubbed-Bronze-Door-Hinge-Value-Pack/181712701?selected=true
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'wayfair.ca',
    retailer_name: 'Wayfair CA',
    retailer_db_name: 'Wayfair.ca',
    requirements: [
      (
        <li key="wayfairca-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          &nbsp;
          .
        </li>
      ),
      (
        <li key="wayfairca-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="wayfairca-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            .html
          </span>
          .
        </li>
      ),
      (
        <li key="wayfairca-requirement-4">
          If this item is a variant, its
          &nbsp;
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?piid=[8 digit #]
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="wayfairca-example-1">
          Non-variant:
          &nbsp;
          <span className="example-url">
            https://www.wayfair.ca/appliances/pdp/broan-nutone-arkbws-optional-recirculation-kit-for-bws1-range-hoods-aqgv1184.html
          </span>
        </li>
      ),
      (
        <li key="wayfairca-example-2">
          Variant:
          &nbsp;
          <span className="example-url">
            https://www.wayfair.ca/appliances/pdp/broan-30-broan-nutone-300cfm-convertible-under-cabinet-range-hood-aqgv1205.html?piid=54371314
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'wayfair.com',
    retailer_name: 'Wayfair US',
    retailer_db_name: 'Wayfair.com',
    requirements: [
      (
        <li key="wayfair-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          &nbsp;
          .
        </li>
      ),
      (
        <li key="wayfair-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="wayfair-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            .html
          </span>
          .
        </li>
      ),
      (
        <li key="wayfair-requirement-4">
          If this item is a variant, its
          &nbsp;
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?piid=[8 digit #]
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="wayfair-example-1">
          Non-variant:
          &nbsp;
          <span className="example-url">
            https://www.wayfair.com/school-furniture-and-supplies/pdp/suncast-patio-33-gallon-curbside-trash-recycling-bin-xa1386.html
          </span>
        </li>
      ),
      (
        <li key="wayfair-example-2">
          Variant:
          &nbsp;
          <span className="example-url">
            https://www.wayfair.com/bed-bath/pdp/tempur-pedic-tempur-adapt-11-medium-mattress-sbja1019.html?piid=30305744
          </span>
        </li>
      ),
    ],
  },
  {
    retailer_home: 'wayfair.co.uk',
    retailer_name: 'Wayfair UK',
    retailer_db_name: 'Wayfair.co.uk',
    requirements: [
      (
        <li key="wayfaircouk-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          &nbsp;
          .
        </li>
      ),
      (
        <li key="wayfaircouk-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
      (
        <li key="wayfaircouk-requirement-3">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            .html
          </span>
          .
        </li>
      ),
      (
        <li key="wayfaircouk-requirement-4">
          If this item is a variant, its
          &nbsp;
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must end with
          &nbsp;
          <span className="url-fragment">
            ?piid=[8 digit #]
          </span>
        </li>
      ),
    ],
    examples: [
      (
        <li key="wayfaircouk-example-1">
          Non-variant:
          &nbsp;
          <span className="example-url">
            https://www.wayfair.co.uk/garden/pdp/blue-elephant-iron-bench-u002337345.html
          </span>
        </li>
      ),
      (
        <li key="wayfaircouk-example-2">
          Variant:
          &nbsp;
          <span className="example-url">
            https://www.wayfair.co.uk/garden/pdp/blue-elephant-alpine-fibreglass-jar-water-fountain-with-light-u002337451.html?piid=1824151954
          </span>
        </li>
      ),
    ],
  },
];
export const deprecatedRetailerUrlFormats = [
  {
    retailer_home: 'sears.com',
    retailer_name: 'Sears',
    retailer_db_name: 'Sears',
    requirements: [
      (
        <li key="sears-requirement-1">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain
          &nbsp;
          <span className="product-field">
            Retailer SKU
          </span>
          .
        </li>
      ),
      (
        <li key="sears-requirement-2">
          <span className="product-field">
            Product URL
          </span>
          &nbsp;
          must contain product information.
        </li>
      ),
    ],
    examples: [
      (
        <li key="sears-example-1">
          <span className="example-url">
            https://www.sears.com/ge-gfd28gslss-27.8cu-ft-french-door-refrirator-with-door/p-A018702711
          </span>
        </li>
      ),
    ],
  },
];
export default retailerUrlFormats;

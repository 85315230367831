/* istanbul ignore file */

import React from 'react';
import createDataCollectionConfig from './types';
import { authorizeAmazonAdvertisingApp, getCountryCodeFromDCA } from './sharedMethods';
import { DataCollectionAccountWithSettings } from '../../controllers/subscription-service/types';
import DCAInstructionsAmazonAdvertising from '../../components/DCAInstructions/DCAInstructionsAmazonAdvertising';

enum OfferedSubscriptionIdEnum {
  demand_side_platform_gas_ca = '2dd0bc59-edc3-4c8e-ae6f-3df4de04f9b5',
  demand_side_platform_gas_us = '73474133-d803-4ae1-85c3-3e83b16f0b50',
}

const countryCodes = ['CA', 'US'] as const;
type CountryCode = typeof countryCodes[number];

const VisibleOfferedSubscriptions: ReadonlyArray<OfferedSubscriptionIdEnum> = [
  OfferedSubscriptionIdEnum.demand_side_platform_gas_ca,
  OfferedSubscriptionIdEnum.demand_side_platform_gas_us,
];

const baseSubscriptionQueryTranslation: Readonly<Record<string, string>> = {
  code: '__oauth_grant_code',
};

const SubscriptionQueryTranslations: Readonly<
Partial<Record<OfferedSubscriptionIdEnum, Record<string, string>>>
> = {
  [OfferedSubscriptionIdEnum.demand_side_platform_gas_ca]: baseSubscriptionQueryTranslation,
  [OfferedSubscriptionIdEnum.demand_side_platform_gas_us]: baseSubscriptionQueryTranslation,
};

// can't use the Readonly<> typing here.. conflicts with the parent object.
const baseUniqueValues: string[] = [
  'profile_id',
];

const SubscriptionSettingUniqueValues: Readonly<
Partial<Record<OfferedSubscriptionIdEnum, string[]>>
> = {
  [OfferedSubscriptionIdEnum.demand_side_platform_gas_ca]: baseUniqueValues,
  [OfferedSubscriptionIdEnum.demand_side_platform_gas_us]: baseUniqueValues,
} as Record<OfferedSubscriptionIdEnum, string[]>;

const consentRequestUrls: Readonly<Record<string, string>> = {
  NORTH_AMERICA: 'https://www.amazon.com/ap/oa',
  EUROPE: 'https://eu.account.amazon.com/ap/oa',
  FAR_EAST: 'https://apac.account.amazon.com/ap/oa',
};

const authUrls: Readonly<Record<CountryCode, string>> = {
  CA: consentRequestUrls.NORTH_AMERICA,
  US: consentRequestUrls.NORTH_AMERICA,
};

const getAuthUrl = (
  countryCode: CountryCode,
): URL | null => (
  (countryCode in authUrls)
    ? new URL(authUrls[countryCode])
    : null
);

const dcaInstructionsComponent: Record<OfferedSubscriptionIdEnum, React.FC | JSX.Element> = {
  [OfferedSubscriptionIdEnum.demand_side_platform_gas_ca]: DCAInstructionsAmazonAdvertising,
  [OfferedSubscriptionIdEnum.demand_side_platform_gas_us]: DCAInstructionsAmazonAdvertising,
};

const disableAccountNameEntry: ReadonlyArray<OfferedSubscriptionIdEnum> = [];

export const authorize = (dca: DataCollectionAccountWithSettings): undefined | Error => {
  const dcaCountryCode = getCountryCodeFromDCA(dca) as CountryCode;
  if (!dcaCountryCode) {
    return new Error('Country Code not found');
  }
  const authUrl = getAuthUrl(dcaCountryCode);
  if (!authUrl) {
    return new Error('Auth URL not found');
  }
  return authorizeAmazonAdvertisingApp(dca, authUrl);
};

export const hasRequiredSettings = (
  dca: DataCollectionAccountWithSettings,
): boolean => dca.offered_subscription_id in SubscriptionSettingUniqueValues
  && !dca.settings.some(
    ({ key }) => key === '__oauth_grant_code',
  );

export default createDataCollectionConfig<OfferedSubscriptionIdEnum>(
  OfferedSubscriptionIdEnum,
  VisibleOfferedSubscriptions,
  SubscriptionQueryTranslations,
  SubscriptionSettingUniqueValues,
  disableAccountNameEntry,
  authorize,
  hasRequiredSettings,
  dcaInstructionsComponent,
);

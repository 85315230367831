import DCAInstructionsAmazonVCPOS from '../../components/DCAInstructions/DCAInstructionsAmazonVCPOS';
import { DataCollectionAccountWithSettings } from '../../controllers/subscription-service/types';
import { authorizeAmazonApp, getMarketPlacesFromDCA } from './sharedMethods';
import createDataCollectionConfig from './types';

enum OfferedSubscriptionIdEnum {
  vendor_central_pos_amazon_ca = '0a1f1fdb-e897-4d4f-ae51-68989a777050',
  vendor_central_pos_amazon_mx = '6ab62032-6174-459a-a948-2980cee2df1c',
  vendor_central_pos_amazon_uk = '39785a70-e7c2-4f62-95da-091640333e91',
  vendor_central_pos_amazon_us = 'e56afec2-771e-4b6a-98db-11326b77b46e',
}

const marketplaces = ['CA', 'MX', 'UK', 'US'] as const;
type Marketplace = typeof marketplaces[number];

const VisibleOfferedSubscriptions: ReadonlyArray<OfferedSubscriptionIdEnum> = [
  OfferedSubscriptionIdEnum.vendor_central_pos_amazon_ca,
  OfferedSubscriptionIdEnum.vendor_central_pos_amazon_mx,
  OfferedSubscriptionIdEnum.vendor_central_pos_amazon_uk,
  OfferedSubscriptionIdEnum.vendor_central_pos_amazon_us,
];

const SubscriptionQueryTranslations: Readonly<
Record<OfferedSubscriptionIdEnum,
Record<string, string>
>
> = {
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_ca]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_mx]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_uk]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_us]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
};

const SubscriptionSettingUniqueValues: Readonly<
Record<
OfferedSubscriptionIdEnum, string[]
>
> = {
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_ca]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_mx]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_uk]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_us]: [
    'account_name',
    'selling_partner_id',
  ],
};

const authUrls: Readonly<Record<Marketplace, string>> = {
  CA: 'https://vendorcentral.amazon.ca/apps/authorize/consent',
  MX: 'https://vendorcentral.amazon.com.mx/apps/authorize/consent',
  UK: 'https://vendorcentral.amazon.co.uk/apps/authorize/consent',
  US: 'https://vendorcentral.amazon.com/apps/authorize/consent',
};

const disableAccountNameEntry: ReadonlyArray<OfferedSubscriptionIdEnum> = [];

const getAuthUrl = (
  marketplaceCodes: Marketplace[],
): URL | null => (
  (marketplaceCodes.length > 0 && marketplaceCodes[0] in authUrls)
    ? new URL(authUrls[marketplaceCodes[0]])
    : null
);

const dcaInstructionsComponent: Record<OfferedSubscriptionIdEnum, React.FC | JSX.Element> = {
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_ca]: DCAInstructionsAmazonVCPOS,
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_mx]: DCAInstructionsAmazonVCPOS,
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_uk]: DCAInstructionsAmazonVCPOS,
  [OfferedSubscriptionIdEnum.vendor_central_pos_amazon_us]: DCAInstructionsAmazonVCPOS,
};

export const authorize = (dca: DataCollectionAccountWithSettings): undefined | Error => {
  const dcaMarketplace = getMarketPlacesFromDCA(dca) as Marketplace[];
  if (!dcaMarketplace) {
    return new Error('Marketplace not found');
  }
  const authUrl = getAuthUrl(dcaMarketplace);
  if (!authUrl) {
    return new Error('Auth URL not found');
  }
  return authorizeAmazonApp(dca, authUrl);
};

export const hasRequiredSettings = (
  dca: DataCollectionAccountWithSettings,
): boolean => dca.offered_subscription_id in SubscriptionSettingUniqueValues
  && !dca.settings.some(
    ({ key }) => key === '__spapi_oauth_code',
  );

export default createDataCollectionConfig<OfferedSubscriptionIdEnum>(
  OfferedSubscriptionIdEnum,
  VisibleOfferedSubscriptions,
  SubscriptionQueryTranslations,
  SubscriptionSettingUniqueValues,
  disableAccountNameEntry,
  authorize,
  hasRequiredSettings,
  dcaInstructionsComponent,
);

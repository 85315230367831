import React, { useRef } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Search } from '../../icons';
import './SearchInput.css';

type SearchInputProps = {
  placeholder?: string,
  'data-testid'?: string,
  className?: string,
  onBlur?: React.FocusEventHandler<HTMLInputElement>,
  onFocus?: React.FocusEventHandler<HTMLInputElement>,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  onClick?: React.MouseEventHandler<HTMLInputElement>,
  disabled?: boolean,
  value?: string,
  defaultValue?: string,
  inputRef?: React.MutableRefObject<null>
};

function SearchInput({
  placeholder,
  className,
  onBlur,
  onFocus,
  onChange,
  onClick,
  'data-testid': dataTestID,
  disabled,
  value,
  defaultValue,
  inputRef,
}: SearchInputProps) {
  const ref = inputRef ?? useRef(null);

  const focusOnInput = () => {
    (ref.current as unknown as HTMLInputElement).focus();
  };

  const handleClick: React.MouseEventHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    focusOnInput();
    e.preventDefault();
    if (onClick) {
      onClick(e);
    }
  };

  const iconClass = disabled === true ? 'bg-disabled' : '';

  return (
    <InputGroup
      className={`${className ?? ''} search-input-group border rounded`}
      onClick={handleClick}
    >
      <InputGroup.Text
        className={`search-input-label ${iconClass}`}
        onClick={handleClick}
      >
        <Search
          onFocus={focusOnInput}
          className="search-input-icon"
          fill="#CCCCCC"
        />
      </InputGroup.Text>
      <FormControl
        type="search"
        ref={ref}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        data-testid={dataTestID}
        disabled={disabled}
        className="search-input-control"
        aria-label="Search"
        onChange={onChange}
        onKeyDown={(e) => e.stopPropagation()}
        value={value}
        defaultValue={defaultValue}
      />
    </InputGroup>
  );
}

SearchInput.defaultProps = {
  value: undefined,
  placeholder: '',
  className: '',
  onFocus: (e: React.FocusEvent<HTMLInputElement, Element>) => e.preventDefault(),
  onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => e.preventDefault(),
  onChange: (e: React.FocusEvent<HTMLInputElement, Element>) => e.preventDefault(),
  onClick: (e: React.MouseEvent) => e.preventDefault(),
  'data-testid': '',
  disabled: false,
  defaultValue: undefined,
  inputRef: undefined,
};

export default SearchInput;

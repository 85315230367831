import DCAInstructionsAmazonSCPOS from '../../components/DCAInstructions/DCAInstructionsAmazonSCPOS';
import { DataCollectionAccountWithSettings } from '../../controllers/subscription-service/types';
import { authorizeAmazonApp, getMarketPlacesFromDCA } from './sharedMethods';
import createDataCollectionConfig from './types';

enum OfferedSubscriptionIdEnum {
  seller_central_pos_amazon_ca = 'cce294a6-37e7-441a-8fe3-e92242f7deed',
  seller_central_pos_amazon_us = 'f39746e2-8513-45a2-80f6-ad67d3c57f43',
}
const marketplaces = ['CA', 'US'] as const;
type Marketplace = typeof marketplaces[number];

const VisibleOfferedSubscriptions: ReadonlyArray<OfferedSubscriptionIdEnum> = [
  OfferedSubscriptionIdEnum.seller_central_pos_amazon_ca,
  OfferedSubscriptionIdEnum.seller_central_pos_amazon_us,
];

const SubscriptionQueryTranslations: Readonly<
Record<OfferedSubscriptionIdEnum, Record<string, string>>
> = {
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_ca]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_us]: {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
};

const SubscriptionSettingUniqueValues: Readonly<Record<OfferedSubscriptionIdEnum, string[]>> = {
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_ca]: [
    'account_name',
    'selling_partner_id',
  ],
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_us]: [
    'account_name',
    'selling_partner_id',
  ],
};

const authUrls: Readonly<Record<Marketplace, string>> = {
  CA: 'https://sellercentral.amazon.ca/apps/authorize/consent',
  US: 'https://sellercentral.amazon.com/apps/authorize/consent',
};

const disableAccountNameEntry: ReadonlyArray<OfferedSubscriptionIdEnum> = [];

const getAuthUrl = (
  marketplaceCodes: Marketplace[],
): URL | null => (
  (marketplaceCodes.length > 0 && marketplaceCodes[0] in authUrls)
    ? new URL(authUrls[marketplaceCodes[0]])
    : null
);

const dcaInstructionsComponent: Record<OfferedSubscriptionIdEnum, React.FC | JSX.Element> = {
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_ca]: DCAInstructionsAmazonSCPOS,
  [OfferedSubscriptionIdEnum.seller_central_pos_amazon_us]: DCAInstructionsAmazonSCPOS,
};

export const authorize = (dca: DataCollectionAccountWithSettings): undefined | Error => {
  const dcaMarketplace = getMarketPlacesFromDCA(dca) as Marketplace[];
  if (!dcaMarketplace) {
    return new Error('Marketplace not found');
  }
  const authUrl = getAuthUrl(dcaMarketplace);
  if (!authUrl) {
    return new Error('Auth URL not found');
  }
  return authorizeAmazonApp(dca, authUrl);
};

export const hasRequiredSettings = (
  dca: DataCollectionAccountWithSettings,
): boolean => dca.offered_subscription_id in SubscriptionSettingUniqueValues
  && !dca.settings.some(
    ({ key }) => key === '__spapi_oauth_code',
  );

export default createDataCollectionConfig<OfferedSubscriptionIdEnum>(
  OfferedSubscriptionIdEnum,
  VisibleOfferedSubscriptions,
  SubscriptionQueryTranslations,
  SubscriptionSettingUniqueValues,
  disableAccountNameEntry,
  authorize,
  hasRequiredSettings,
  dcaInstructionsComponent,
);

/* istanbul ignore file */
import AmazonDemandSidePlatformGas from './amazonDemandSidePlatformGas';
import AmazonSellerCentralPos from './amazonSellerCentralPos';
import AmazonSearchAdvertisingGas from './amazonSearchAdvertisingGas';
import AmazonVendorCentralPos from './amazonVendorCentralPos';
import CriteoRetailMedia from './criteoRetailMedia';
import TargetRoundel from './targetRoundelAdvertising';
import WalmartConnectAdvertising from './walmartConnectAdvertising';
import { DataCollectionAccountWithSettings } from '../../controllers/subscription-service/types';
import sendRumError from '../datadogRum';

const DataCollectionConfigs = [
  AmazonDemandSidePlatformGas,
  AmazonSearchAdvertisingGas,
  AmazonSellerCentralPos,
  AmazonVendorCentralPos,
  CriteoRetailMedia,
  TargetRoundel,
  WalmartConnectAdvertising,
];

export default {
  AmazonDemandSidePlatformGas,
  AmazonSearchAdvertisingGas,
  AmazonSellerCentralPos,
  AmazonVendorCentralPos,
  CriteoRetailMedia,
  TargetRoundel,
  WalmartConnectAdvertising,
};

export const SubscriptionSettingUniqueValues = Object.assign(
  {},
  ...(DataCollectionConfigs.map((config) => config.SubscriptionSettingUniqueValues)),
);

export const SubscriptionQueryTranslations = Object.assign(
  {},
  ...(DataCollectionConfigs.map((config) => config.subscriptionQueryTranslations)),
);

export const DCAInstructionsComponent: Record<string, React.ReactNode> = Object.assign(
  {},
  ...(DataCollectionConfigs.map((config) => config.dcaInstructionsComponent)),
);

export const DisableAccountNameEntry: string[] = DataCollectionConfigs
  .flatMap((config) => config.disableAccountNameEntry as string[]);

export const OrganizationAdminVisibleOfferedSubscriptions: string[] = DataCollectionConfigs
  .flatMap((config) => config.visibleOfferedSubscriptions as string[]);

type ValidateAndAuthorizeReturn = {
  hasRequiredSettings: boolean;
  authorize: (dca: DataCollectionAccountWithSettings) => undefined | void | Error;
};

export const validateAndAuthorize = (
  dca: DataCollectionAccountWithSettings,
):ValidateAndAuthorizeReturn => {
  try {
    const config = DataCollectionConfigs.find(
      (c) => c.hasRequiredSettings(dca),
    );

    return {
      hasRequiredSettings: !!config,
      authorize: config?.authorize || (() => undefined),
    };
  } catch (e) {
    sendRumError(e);
    return {
      hasRequiredSettings: false,
      authorize: () => e as Error,
    };
  }
};

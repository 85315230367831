import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { HelpLinkButton } from '../modal/helpModal/helpModal';
import { Trash } from '../../icons';

const DCAInstructionsCriteoRetailMedia: JSX.Element = (
  <Container
    className="mb-3"
    data-testid="dca-instructions-criteo"
  >
    <Row>
      <h3
        className="text-capitalize mt-2"
      >
        How to add Criteo Retail Media Accounts
      </h3>
    </Row>
    <Row>
      <Container>
        <b>
          Note:
        </b>
        <ul>
          <li>
            Marshall Insights separates Criteo account data into Account-Retailer combinations
            for security.
          </li>
          <li>
            Criteo accounts already in the Insights application cannot be added again.
          </li>
          <li>
            If you need to add more Account-Retailer combinations, reauthorize the Insights
            application to collect the additional data.
          </li>
        </ul>
      </Container>
    </Row>
    <Row className="ps-3 pad-child-list-elements">
      <ol>
        <li>
          <b>
            Sign into Criteo:
          </b>
          <ul>
            <li>
              Use a separate browser tab to sign in to Criteo.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Authorize the account(s):
          </b>
          <ul>
            <li>
              Click the&nbsp;
              <span className="badge bg-black">
                Add
              </span>
              &nbsp;button below.
            </li>
            <li>
              You will be redirected to Criteo for authorization.
            </li>
            <li>
              Once on the Criteo site, scroll down to find &quot;Portfolio Access&quot;.
            </li>
            <li>
              Select the account(s) you want to authorize.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Grant access:
          </b>
          <ul>
            <li>
              Click the &quot;Approve&quot; button.
            </li>
            <li>
              Then, confirm by clicking &quot;Confirm Approval&quot;.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Review the authorized Criteo accounts:
          </b>
          <ul>
            <li>
              Check that all authorized accounts appear in this interface.
            </li>
            <li>
              To remove any account, click the trash icon (
              <Trash fill="var(--blushing-salmon)" />
              ) next to it.
            </li>
            <li>
              If any accounts are missing, repeat the above steps to add them.
            </li>
          </ul>
        </li>
      </ol>
    </Row>
    <Row>
      <b>
        Troubleshooting:
      </b>
      <p>
        If you encounter any issues or have questions, contact
        <HelpLinkButton />
        .
      </p>
    </Row>
  </Container>
);

/* Instructions for re-authorization: in storage until we're ready to deploy it
    <Row>
      <b>
        How to re-authorize access to Criteo:
      </b>
      <br />
      <p>
        <ul>
          <li>
            Open&nbsp;
            <NavLink
              to="https://consent.criteo.com"
              target="_blank"
            >
              https://consent.criteo.com
            </NavLink>
          </li>
          <li>
            Select the account(s) to be re-authorized in the &quot;Marshall Insights&quot; Section
          </li>
          <li>
            Click on &quot;Revoke selected access&quot;.
          </li>
        </ul>
      </p>
    </Row>
*/

export default DCAInstructionsCriteoRetailMedia;

import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { fetchAllowedDomainsCheck, putOrganizationAllowedDomains } from '../../../controllers/user-service';
import { AsyncState } from '../../../utils/webRequests.type';
import { validateDomains } from '../../../utils/validateRegex';

type AllowedDomainsModalPropTypes = {
  currentOrganizationId: number;
  allowedDomains: string[];
  onAllowedDomainSet: () => Promise<unknown>;
};

function InvalidUserAlert(props: { invalidUsers: string[] }) {
  const { invalidUsers } = props;
  if (invalidUsers.length === 0) {
    return null;
  }

  return (
    <Alert data-testid="allowed-domains-invalid-user-alert" className="p-1 m-0" variant="warning">
      <p className="p-0 m-0">
        {
          invalidUsers.length === 1
            ? 'There is a user with a domain not on the allowed domain list. This user will still be able to log in until removed.'
            : `There are ${invalidUsers.length} users with domains not on the allowed domain list. These users will still be able to log in until removed.`
        }
      </p>
    </Alert>
  );
}

function AllowedDomainsModal(props: AllowedDomainsModalPropTypes) {
  const { currentOrganizationId, allowedDomains, onAllowedDomainSet } = props;
  const [show, setShow] = useState<boolean>(false);
  const allowedDomainsInputRef = useRef<HTMLTextAreaElement>(null);

  const [allowedDomainCandidate, setAllowedDomainCandidate] = useState<string[]>(allowedDomains);
  const [areCandiatesValid, setAreCandidatesValid] = useState<boolean>(true);

  const [usersWhoAreNotValid, setUsersWhoAreNotValid] = useState<string[]>([]);
  const [saveStatus, setSaveStatus] = useState<AsyncState>('uninitialized');
  const [domainFetchStatus, setDomainFetchStatus] = useState<AsyncState>('loading');

  useEffect(() => {
    setSaveStatus('uninitialized');
    setAreCandidatesValid(
      validateDomains(allowedDomainCandidate),
    );
  }, [allowedDomainCandidate]);

  useEffect(() => {
    setDomainFetchStatus('loading');
    fetchAllowedDomainsCheck(currentOrganizationId)
      .then(({ data }) => {
        setUsersWhoAreNotValid(data.invalid_users);
        setDomainFetchStatus('completed');
      })
      .catch(() => {
        setDomainFetchStatus('failed');
      });
  }, [allowedDomains]);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    if (saveStatus !== 'loading') {
      setShow(false);
    }
  };

  const handleSave = () => {
    setSaveStatus('loading');
    const newAllowedDomains = (allowedDomainsInputRef.current?.value as string)
      .split('\n')
      .map((domain) => domain.trim())
      .filter((domain) => domain.length > 0);

    putOrganizationAllowedDomains(
      currentOrganizationId,
      newAllowedDomains,
    )
      .then(() => onAllowedDomainSet())
      .then(() => {
        setSaveStatus('completed');
      })
      .catch(() => {
        setSaveStatus('failed');
      });
  };

  const isLoading = ['uninitialized', 'loading'].includes(domainFetchStatus);

  return (
    <>
      <span
        hidden={
          (
            domainFetchStatus === 'completed'
            && allowedDomains.length !== 0
          ) || isLoading
        }
        className="text-gizmo-grey"
      >
        Click edit to set allowed domains.
      </span>
      <span
        data-testid="allowed-domains-loading"
        hidden={
         !isLoading
        }
        className="text-gizmo-grey"
      >
        loading...
      </span>
      <span
        hidden={domainFetchStatus !== 'failed' || isLoading}
        className="text-gizmo-grey"
      >
        Error loading domains.
        <br />
        Please Refresh and try again
      </span>
      <li>
        <button
          type="button"
          className="ma-btn-link ma-link"
          onClick={handleShow}
        >
          Edit
        </button>
      </li>
      <Modal
        className="org-modal mt-5 mh-50"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Allowed Domains</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-2">
            Control which email domains have access to your data.
            Users who have an email without one of these
            domains will not be able to be invited to the app.
          </p>
          <Form.Group className="height-75">
            <Form.Control
              className="shadow-none"
              as="textarea"
              placeholder="Enter allowed domains here (e.g. company.com)"
              rows={6}
              defaultValue={allowedDomains.join('\n')}
              ref={allowedDomainsInputRef}
              onChange={
                (e) => {
                  const { value } = e.currentTarget;
                  setAllowedDomainCandidate(
                    value.split('\n')
                      .map((domain) => domain.trim().toLowerCase())
                      .filter((domain) => domain.length > 0),
                  );
                }
              }
              isValid={saveStatus === 'completed'}
              isInvalid={!areCandiatesValid}
            />
            <Form.Control.Feedback
              type="invalid"
            >
              At least one domain is not valid
            </Form.Control.Feedback>
          </Form.Group>
          <span className="text-gizmo-grey">Domains should be separated by new lines</span>
          <InvalidUserAlert invalidUsers={usersWhoAreNotValid} />
          <Alert
            hidden={saveStatus !== 'failed'}
            data-testid="allowed-domains-invalid-user-alert-error"
            className="p-1 m-0"
            variant="danger"
          >
            Something went wrong. Please refresh and try again.
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={saveStatus === 'loading'}
            className="col-2"
            variant="danger"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            data-testid="allowed-domains-save-button"
            disabled={
              saveStatus === 'loading'
              || !areCandiatesValid
              || allowedDomains === allowedDomainCandidate // disabled until a change is made
            }
            className="col-2"
            variant="primary"
            onClick={handleSave}
          >
            {
              saveStatus === 'loading'
                ? <Spinner size="sm" animation="grow" />
                : 'Save'
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AllowedDomainsModal;

export type RegexRowError = {
  key: string,
  message: string,
};

// phone number regex from https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

export const isInvalidRegexValue = (
  v: string | string[],
  regex_expression: string,
) => {
  const regex = new RegExp(regex_expression);
  if (Array.isArray(v)) {
    return !regex.test(JSON.stringify(v));
  }
  return !regex.test(v);
};

export const validateDomains = (domains: string[]): boolean => (
  domains.every((d) => domainRegex.test(d))
);

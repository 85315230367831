import React from 'react';

import './PublicHome.css';
import { Card, Container } from 'react-bootstrap';
import { World, Graph1, Speaker } from '../../icons';
import NavFooter from '../../components/navbar/NavFooter';
import BookADemoModal from '../../components/modal/bookADemoModal/bookADemoModal';

function PublicHome() {
  document.title = 'Marshall Insights';

  return (
    <div id="public-home-page" className="public-home-page display-grid container justify-content-center">
      <div id="public-home-hero-banner">
        <Container
          className="row row-cols-1 row-cols-xl-3 justify-content-evenly align-items-center mx-auto"
          style={{
            maxWidth: '1000px !important',
          }}
        >
          <div className="col bg-transparent">
            <Card className="banner-card mx-auto shadow">
              <h5>Product Monitoring</h5>
              <br />
              <center>
                <World
                  fill="black"
                  style={{
                    height: '10rem',
                    width: '10rem',
                  }}
                />
              </center>
              <br />
              <center>
                <div className="w-10 text-left">
                  <ul>
                    <li>
                      Ensure your product listings are healthy and
                      optimized to maximize sales performance
                    </li>
                    <li>
                      Stop missing opportunities because your listings
                      are unavailable due to inventory outages, 3rd
                      party sellers, or retailer profitability issues
                    </li>
                    <li>
                      Don’t spend precious time clicking through
                      product pages to check for issues related to
                      availability, pricing, content, or reviews
                    </li>
                  </ul>
                </div>
              </center>
            </Card>
          </div>
          <div className="col bg-transparent">
            <Card className="banner-card mx-auto shadow">
              <h5>Sales &amp; Advertising Analytics</h5>
              <br />
              <center>
                <Graph1
                  fill="black"
                  style={{
                    height: '10rem',
                    width: '10rem',
                  }}
                />
              </center>
              <br />
              <center>
                <div className="w-100 text-left">
                  <ul>
                    <li>
                      Determine which of your products are performing well or underperforming
                    </li>
                    <li>
                      Easily analyze period over period performance trends for key KPIs
                    </li>
                    <li>
                      Remove data collection, data centralization, and data
                      harmonization from your to-do list
                    </li>
                  </ul>
                </div>
              </center>
            </Card>
          </div>
          <div className="col bg-transparent">
            <Card className="banner-card mx-auto shadow">
              <h5>Share of Voice</h5>
              <br />
              <center>
                <Speaker
                  fill="black"
                  style={{
                    height: '10rem',
                    width: '10rem',
                  }}
                />
              </center>
              <br />
              <center>
                <div className="w-100 text-left">
                  <ul>
                    <li>
                      Always have a sense of where your product listings
                      stack up against your competition
                    </li>
                    <li>
                      Understand how advertising and content efforts affect
                      your search rank and sales
                    </li>
                    <li>
                      Keep an eye on competitor advertising activity and
                      new market entrants
                    </li>
                  </ul>
                </div>
              </center>
            </Card>
          </div>
        </Container>
      </div>

      <div id="public-home-retailers-supported" className="py-5 mx-auto">
        <h3 className="fw-light text-center">
          Retailers Supported
        </h3>
        <div className="row row-cols-3 row-cols-lg-5 justify-content-between align-items-center">
          <div className="col retailer-card mx-auto d-sm-inline-block d-lg-none">
            <img
              src="/assets/images/amazon_v2.png"
              className="w-100"
              alt="Amazon"
            />
          </div>
          <div className="col retailer-card mx-auto">
            <img
              src="/assets/images/THD_v2.jpg"
              className="w-100"
              alt="The Home Depot"
            />
          </div>
          <div className="col retailer-card mx-auto">
            <img
              src="/assets/images/target_v2.png"
              className="w-100"
              alt="Target"
            />
          </div>
          <div className="col retailer-card mx-auto d-sm-none d-lg-inline-block">
            <img
              src="/assets/images/amazon_v2.png"
              className="w-100"
              alt="Amazon"
            />
          </div>
          <div className="col retailer-card mx-auto">
            <img
              src="/assets/images/walmart_v2.png"
              className="w-100"
              alt="Walmart"
            />
          </div>
          <div className="col retailer-card mx-auto">
            <img
              src="/assets/images/wayfair_v2.png"
              className="w-100"
              alt="Wayfair"
            />
          </div>
        </div>
        <div className="text-center mt-4">
          And Many More!
        </div>
      </div>

      <div id="public-home-ecomm-central" className="p-5">
        <Container
          className="row row-cols-1 justify-content-evenly align-items-center mx-auto"
          style={{
            maxWidth: '1000px !important',
          }}
        >
          <div className="col">
            <div className="row">
              <div className="col">
                <center>
                  <h2>
                    Trouble Centralizing Your E-Comm Data?
                  </h2>
                </center>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-2 justify-content-center">
              <div
                className="flex col justify-content-center align-items-end pt-3"
                style={{
                  height: '20rem',
                }}
              >
                <span>
                  <b>
                    Marshall Insights
                  </b>
                  &nbsp;
                  is a proprietary software that provides data and actionable
                  recommendations on how to improve product listings and drive
                  sales. A unique solution presented in an easy-to-digest
                  platform designed in consultation with a team of E-Commerce
                  experts who have years of experience maximizing business
                  opportunities. Marshall Insights can be used for augmenting
                  your sales and advertising efforts on multiple platforms,
                  including but not limited to: Amazon, Bed Bath and Beyond,
                  Home Depot, Lowe&lsquo;s, Meijer, Menards, Sam&lsquo;s Club, Target,
                  Walmart, and Wayfair. To home in on each facet of your online
                  offering, the platform focuses on four major areas: Product
                  Monitoring, Sales Analytics, Advertising Analytics, and Share of Voice
                </span>
              </div>
              <div className="col flex justify-content-center pt-3">
                <img
                  src="/assets/images/amazon-vendor-central-pos-chart.png"
                  className="shadow"
                  alt="sample chart"
                  style={{
                    height: 'auto',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '20rem',
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-10 mt-5">
                <center>
                  <BookADemoModal />
                </center>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <NavFooter />
    </div>
  );
}

export default PublicHome;

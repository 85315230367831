import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store'; // eslint-disable-line import/no-cycle
import {
  getTokenValues,
} from '../../controllers/auth';
import {
  fetchOrganizations,
} from '../../controllers/user-service';
import { OrganizationType } from '../../controllers/user-service/types';
import { AsyncState } from '../../utils/webRequests.type';

export type OrganizationsState = {
  status: AsyncState;
  data: OrganizationType[];
};

export type ApplicationState = {
  organizations: OrganizationsState,
};

const initialState: OrganizationsState = {
  status: 'uninitialized',
  data: [],
};

export const updateOrganizations = createAsyncThunk(
  'organizations/fetchOrganizations',
  async () => {
    const tokenValues = getTokenValues();
    const response = await fetchOrganizations(tokenValues?.default_organization_id);
    return response.data;
  },
);

export const updateOrganizationsSilent = createAsyncThunk(
  'organizations/fetchOrganizationsSilent',
  async () => {
    const tokenValues = getTokenValues();
    const response = await fetchOrganizations(tokenValues?.default_organization_id);
    return response.data;
  },
);

export const OrganizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOrganizationsData(state, action) {
      state.data = action.payload;
    },
    setOrganizationsStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateOrganizations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrganizations.fulfilled, (state, action) => {
        state.status = 'completed';
        state.data = action.payload ?? [];
      })
      .addCase(updateOrganizations.rejected, (state) => {
        state.status = 'failed';
        state.data = initialState.data;
      })
      .addCase(updateOrganizationsSilent.fulfilled, (state, action) => {
        state.status = 'completed';
        state.data = action.payload ?? [];
      });
  },
});

export const selectOrganizationsData = (state: RootState) => state.organizations.data;
export const selectOrganizationsStatus = (state: RootState) => state.organizations.status;
export const selectCurrentOrganization = (state: RootState) => {
  const id = state.profile.currentOrgId;
  if (id) {
    return state.organizations.data?.find((org) => org.id === id);
  }
  return undefined;
};
export const { setOrganizationsData, setOrganizationsStatus } = OrganizationsSlice.actions;
export default OrganizationsSlice.reducer;

import React from 'react';
import createDataCollectionConfig from './types';
import { DataCollectionAccountWithSettings } from '../../controllers/subscription-service/types';

enum OfferedSubscriptionIdEnum {
  target_roundel_advertising = '4703f1f6-198f-404d-b05a-26633e7e881c',
}

// const marketplaces = [] as const;
// type Marketplace = typeof marketplaces[number];

const VisibleOfferedSubscriptions: ReadonlyArray<OfferedSubscriptionIdEnum> = [
  OfferedSubscriptionIdEnum.target_roundel_advertising,
];

const SubscriptionQueryTranslations: Readonly<
Partial<Record<OfferedSubscriptionIdEnum, Record<string, string>>>
> = {};

const SubscriptionSettingUniqueValues: Readonly<Record<OfferedSubscriptionIdEnum, string[]>> = {
} as Readonly<Record<OfferedSubscriptionIdEnum, string[]>>;

const DCAInstructionsComponent: Record<OfferedSubscriptionIdEnum, React.FC | JSX.Element> = {
  [OfferedSubscriptionIdEnum.target_roundel_advertising]: React.Fragment,
};

// const authUrls: Readonly<Record<Marketplace, string>>
const disableAccountNameEntry: ReadonlyArray<OfferedSubscriptionIdEnum> = [
] as ReadonlyArray<OfferedSubscriptionIdEnum>;

// const getAuthUrl = (
//   _marketplaceCodes: Marketplace[],
// ): null => null;

export const authorize = (_dca: DataCollectionAccountWithSettings): void | Error => new Error('This Subscription is not implemented');

export const hasRequiredSettings = (_dca: DataCollectionAccountWithSettings): boolean => false;

export default createDataCollectionConfig<OfferedSubscriptionIdEnum>(
  OfferedSubscriptionIdEnum,
  VisibleOfferedSubscriptions,
  SubscriptionQueryTranslations,
  SubscriptionSettingUniqueValues,
  disableAccountNameEntry,
  authorize,
  hasRequiredSettings,
  DCAInstructionsComponent,
);
